import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

type LinkInfo = { title: string; image: string };

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  private readonly hiddenElement = document.createElement('a');

  constructor(private http: HttpClient) {}

  public getLinkInfo(link: string): Observable<LinkInfo> {
    return this.http.post<LinkInfo>(environment.be_url + '/links/external_link_info', { url: link });
  }

  public downloadSlide(fileUrl: string): void {
    window.open(fileUrl, '_blank');
  }

  public downloadFile(file: Blob, fileName: string): void {
    this.hiddenElement.download = fileName;
    this.hiddenElement.href = window.URL.createObjectURL(file);
    this.hiddenElement.click();
  }
}
